import "./style.scss"
import { useDispatch } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect, useRef } from "react";
import { Carousel } from 'antd';
import { SoundOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import EadlineCom from "../../components/EadlineCom";
import { Navigation, Pagination, EffectCreative } from "swiper/modules";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import swiper from "swiper"
import { HongKongPageData } from "../../utils/data";
import { useState } from "react";
import { getPageListAPI, getBlogsListAPI } from "../../api";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router-dom";
import BlogCom from "../../components/BlogCom";
const HongKongPage = () => {
    const dispatch = useDispatch()
    const carouselRef = useRef(null);
    const [courseDataList, setCourseDataList] = useState([]);
    const [teacherDatalist, setTeacherDatalist] = useState([]);
    const [serviceContentList, setServiceContentList] = useState([]);
    const [website, setWebsite] = useState({});
    const [talkList, setTalkList] = useState([]);
    let colList = ["#C4EFDC", "#F9F3FF", "#E8EBCD"]
    const rollingRef = useRef(null)
    const swiperRef = useRef(null);
    const handleNextSlide = () => {
        swiperRef.current.swiper.slideNext();
    };
    const nav = useNavigate();
    const goMath = () => {
        nav("/hongkongmaths");
    };
    useEffect(() => {
        dispatch(incrementByAmount(1))
        getPageListAPI({ websiteType: '0', courseType: '0', area: '0' }).then((res) => {
            setCourseDataList(res.data.courseList)
            setTeacherDatalist(res.data.teacherList)
            setServiceContentList(res.data.serviceList)
            setTalkList(res.data.talkList)
            setWebsite(res.data.website);
        });

        setTimeout(() => {
            // handleNextSlide();
        }, 500);
        // new swiper('.swiper', {
        //     loop: true, //设置循环轮播
        //     // className="mySwiper"
        //     // spaceBetween: -200, //设置堆叠轮播，item之间叠的距离
        //     slidesPerView: 1, //设置显示的数量
        //     navigation: true,//modules上加了同时要设置为true，才显示
        //     modules: [Navigation, Pagination, EffectCreative],
        //     grabCursor: true,
        //     effect: 'creative',//modules上加了同时要设置
        //     centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        //     slideToClickedSlide: false,// 点击的slide会居中
        //     initialSlide: 0,
        //     creativeEffect: {
        //         prev: {
        //             //这里是设置当前item的前一项的具体属性
        //             // translate: [-200, 0, 0], //偏移量，三个轴，X、Y、Z
        //             scale: 0.8, //缩放量
        //             // opacity:0.8,//透明度
        //             // shadow:true,//是否加阴影
        //             //rotate:30,//item旋转角度
        //             //origin:'left bottom'//item位于左下还是哪里
        //         },
        //         next: {
        //             //这里是设置当前item的后一项的具体属性，同上面
        //             // translate: [200, 0, 0],
        //             scale: 0.8,
        //             // opacity:0.8,
        //             // shadow:true,
        //         },
        //     },
        //     pagination: {
        //         el: '.swiper-pagination',
        //     },
        // });
    }, []);
    // let courseDataList = HongKongPageData.courseDataList;
    const courseItemCom = (item, index) => {
        return (

            <div key={index} className={[(index + 1) % 2 === 0 ? "dual" : "", "courseItem"].join(" ")} >
                <div className="courseLogo"><img src={item.photo} alt="" /></div>
                <div className="courseTitle">{item.name}</div>

                <div className="courseInfo">{item.content}</div>
            </div>

        )
    }
    const chatUsCom = () => {
        return (
            <div className="chatUs">
                <div className=""><img src="../assets/HK/service.png" alt="" /> 點擊咨詢</div>
            </div>
        );
    }
    // let teacherDatalist = HongKongPageData.teacherDatalist;
    const teacherItemCom = (item, index) => {
        return (
            <div key={index} className={['teacherItem', index === 1 ? "higthBox" : ""].join(" ")}>

                <div className="teacherHead" style={{ backgroundColor: colList[index] }}><img src={item.picture} alt="" /></div>
                <div className="teacherContent">
                    <div className="teacherName">
                        {item.name}<span>{item.quality}</span>
                    </div>
                    <div className="teacherInfo">{item.introduce}</div>
                </div>
            </div>
        )
    }
    // let serviceContentList = HongKongPageData.serviceContentList;
    const serviceContentCom1 = (item, index) => {
        return (
            <div key={index} className={['serviceItem', index % 2 != 0 ? "service1" : ""].join(" ")}>
                <div className="left">
                    <div className="serviceTitle">{item.title}</div>
                    <div className="serviceInfo">{item.content}</div>
                </div>
                <div className="right"> <img src={item.picture} alt="" /></div>
            </div>
        )
    }
    return (
        <div className="hkPage">
            <div className="topBut">
                <button className="but2" onClick={() => { goMath() }}>香港数学</button>
                <button className="but1" onClick={() => {
                }} >香港中文</button>
            </div>
            <div className="barrn">
                <img className="imgMob" src={website.photoMob} alt="" />
                <img className="imgPc" src={website.photo} alt="" />
                <div className="nameTitle">

                    <div>香港地區</div>
                    <div className="englishTitle">Hong Kong China</div>
                    {/* <div className="classInfo">港澳地區TOP<a>中文補習網課，</a> <br />無數成績大躍進案例盡在Sinobus！<br />
                        掌握數學，開啟未來無限可能！<br />立即加入Sinobus數學補習網課！</div> */}
                </div>

            </div>
            {/* <VerticalScrollList items={blogList} /> */}
            <EadlineCom name="課程簡介" />
            <div className="courseMol">
                {
                    courseDataList.map((item, index) => {
                        return courseItemCom(item, index)
                    })
                }
            </div>
            {chatUsCom()}
            <EadlineCom name="教師資質" />
            <div className="teachers">
                {teacherDatalist.map((item, index) => {
                    return teacherItemCom(item, index)
                })}
            </div>
            <div className="teachers teacherMob">
                <Carousel effect="fade">
                    {teacherDatalist.map((item, index) => {
                        return teacherItemCom(item, index)
                    })}
                </Carousel>
            </div>
            {chatUsCom()}
            <div className="teacherAptitude">
                <EadlineCom name="教師資質" />
                <div className="aptitudeImg">
                    <img className="aptitudePc" src="../assets/HK/aptitudeImg.png" alt="" />
                    <img className="aptitudeMob" src="../assets/HK/aptitudeImgMob.png" alt="" />
                </div>
                {chatUsCom()}
            </div>
            <EadlineCom name="家長與老師互動" />
            <div className="service">
                <div className="arrow" onClick={() => { carouselRef.current.prev() }}><LeftOutlined /> </div>
                <div className="interact">
                    <div>
                        <Carousel effect="fade" autoplay dots={true} ref={carouselRef}>
                            {talkList.map((item, index) => (
                                <div className="swiperImg" key={index} >
                                    <img className="talkImg" src={item.picture} alt="" />
                                </div>))}

                        </Carousel>
                    </div>
                    {/* <SwiperCom items={talkList} /> */}
                </div>
                <div className="arrow" onClick={() => { carouselRef.current.next() }}>
                    <RightOutlined />
                </div>
            </div>
            {chatUsCom()}
            <EadlineCom name="博客" />
            {

                <BlogCom data='0' />

            }
            <EadlineCom name="服務内容" />
            <div className="serviceContent">
                {serviceContentList.map((item, index) => {
                    return serviceContentCom1(item, index)
                })}
            </div>
            {chatUsCom()}
            <div style={{ marginBottom: "30px" }}></div>
        </div >
    )
}
export default HongKongPage;