import "./style.scss"
import {
    DownOutlined, MenuOutlined
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useSelector, } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
const HeaderCom = (props) => {
    const count = useSelector(state => state.counter.value)
    // 点击背景色切换
    // 是否展示d导航条
    const [isNav, setIsNav] = useState(false);
    // 是否展示小标题
    const [isSmallNav, setIsSmallNav] = useState(true);
    const nav = useNavigate();
    const items = [{
        key: '1',
        label: (
            <div className="homeNav nav" onClick={(e) => switchPages(e, "/hongkong", 1)}>中文</div>
        ),
    },
    {
        key: '2',
        label: (
            <div className="hkNav nav" onClick={(e) => switchPages(e, "/hongkongmaths", 1)} >数学</div>
        ),
    },]

    const switchPage = (url, num) => {
        console.log("000000000000000000000");
        if (num === 0) {
            nav("/")
        } else if (num === 1) {
            nav("/hongkong")
        } else if (num === 2) {
            nav("/hongkong")
        } else if (num === 3) {
            nav("/hongkongmaths")
        } else if (num === 4) {
            nav("/singapore")
        } else if (num === 5) {
            nav("/aboutus")
        }
        // nav(url)
    };
    const switchPages = (e, url, num) => {
        nav(url)
    };
    let navList = ["首页", "香港课程", "中文", "数学", "新加坡", "联系我们"];

    // 展示
    const showNav = () => {
        setIsNav(!isNav);
        if (count === 1 || count === 3) {
            setIsSmallNav(false)
        } else {
            setIsSmallNav(true)
        }
    };
    // 点击手机端导航
    const handelNav = (index) => {
        switchPage("", index);
        setIsNav(false);
    };

    return (
        <div className="header">

            <img src="../assets/logo.png" alt="" />
            <div className="navText">
                <div className={count === 0 ? 'nav' : ""} onClick={(e) => switchPage("/homepage", 0)}>首页</div>
                <Dropdown menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: count === 3 ? ["2"] : ["1"],
                }} placement="bottom" overlayStyle={{ top: '80px', width: '100px', textAlign: "center", }}>
                    <div className={count === 1 || count === 3 ? 'nav' : ""} onClick={(e) => switchPage("/hongkong", 1)}>
                        香港课程<DownOutlined />
                    </div>

                </Dropdown>
                <div className={count === 4 ? 'nav' : ""} onClick={(e) => switchPage("/singapore", 4)}>新加坡</div>
                <div className={count === 5 ? 'nav' : ""} onClick={(e) => switchPage("/aboutus", 5)}>联系我们</div>
            </div>
            <div className="mbMenu">
                <MenuOutlined onClick={() => { showNav() }} />
                {
                    isNav ? <ul className="navList">
                        {
                            navList.map((item, index) => {
                                return (
                                    <li key={index} className={[isSmallNav && index === 2 || isSmallNav && index === 3 ? "hide" : "", index === 2 || index === 3 ? "show" : "", count == index || count === 3 && index === 1 || count == 1 && index === 2 ? 'yellow' : ""].join(' ')} onClick={() => { handelNav(index) }}>
                                        {item} {index === 1 ? <DownOutlined onClick={(e) => { e.stopPropagation(); setIsSmallNav(!isSmallNav) }} /> : ""}</li>
                                )
                            })
                        }
                    </ul> : ""
                }
            </div>

        </div >
    )
}
export default HeaderCom;