import "./style.scss"
import { useSelector, useDispatch } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect } from "react";
import EadlineCom from "../../components/EadlineCom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCreative } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useState, useRef } from "react";
import { SingaporePageData } from "../../utils/data";
import { getPageListAPI } from "../../api";
import swiper from "swiper"
import VerticalScrollList from "../HongKongPage/rollingCom.jsx"
import { Carousel } from 'antd';
import BlogCom from "../../components/BlogCom";
import { SoundOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
const SingaporePage = () => {
    const dispatch = useDispatch()
    const [list, setList] = useState([]);
    const [coursesDataList, setCoursesDataList] = useState([]);
    const [reasonList, setReasonList] = useState([]);
    const [payTypesList, setPayTypesList] = useState([]);
    const [parentsList, setParentsList] = useState([]);
    const [teacherDataList, setTeacherDataList] = useState([]);
    const [banner, setBanner] = useState([]);
    const swiperRef = useRef(null);
    const swiperRef1 = useRef(null);
    const carouselRef = useRef(null);
    const [blogList, setBlogList] = useState([]);
    const handleNextSlide = () => {
        swiperRef.current.swiper.slideNext();
        // swiperRef1.current.swiper.slideNext();
    };
    useEffect(() => {
        dispatch(incrementByAmount(4));
        getPageListAPI({ websiteType: '1', courseType: '0', area: '3' }).then((res) => {
            setCoursesDataList(res.data.courseList);
            setReasonList(res.data.serviceList);
            setParentsList(res.data.talkList);
            setTeacherDataList(res.data.teacherList);
            setBanner(res.data.website.photo);
        })
        setList(SingaporePageData.list);
        setPayTypesList(SingaporePageData.payTypesList);
        new swiper('.swiper', {
            spaceBetween: 0, //slide之间距——间距30
            centeredSlides: false, // 多列居中显示
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            }, //fade属性必须加上

            // 如果需要前进后退按钮
            navigation: {
                nextEl: ".swiper-next",
                prevEl: ".swiper-prev",
            },
            pagination: {
                el: ".swiper-pagination",
            },

        });
        setTimeout(() => {
            handleNextSlide()
        }, 500);
    }, [])
    const smallTitleCom = (data) => {
        return (<div className="smallTitle" ><span className="round"></span>{data}</div>)
    }
    const coursesCom = (item, index) => {
        return (
            <div className="coursesItem" key={index}>
                <div className="coursesImg"><img src={item.photo} alt="" /></div>
                <div className="coursesInfo">
                    <div className="coursesTitle">{item.name}</div>
                    <div className="coursesContent"> {item.content}</div>
                </div>
            </div>
        )
    }
    const payBoxCom = (item, index) => {
        return (<div className="payItem" key={index}>
            <div className="payTime">{item.time}</div>
            <div className="price">{item.price} <span>/Class</span></div>
            <div className="chatUs">Contact Us</div>
        </div>);
    };
    const teachersCom = (item, index) => {
        return (
            <div className="teacherItem" key={index}>
                <div className="imgBag teacherBag3" style={{ backgroundImage: `url(${item.picture})` }}>
                    <div className="teacherContent">{item.introduce}
                    </div>
                </div>
                <div className="teacherDetails">
                    <span className="teacherName">{item.name}</span>
                    <span className="teacherLife">{item.teacherLife}</span>
                </div>
                <div className="line"></div>
                <div className="teacherWord">
                    {item.introduce}
                </div>
                <div className="icon">
                    <div className="iconBox">
                        <img src="../assets/singapore/class22.png" alt="" /><span
                        >{item.quality}</span
                        >
                    </div>
                    <div className="iconBox">
                        <img src="../assets/singapore/class11.png" alt="" /><span
                        >{item.remark}</span
                        >
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="singaporePage" >
            <div className="singaporeHeader"><img src={banner} alt="" />
                <div className="name">Singapore</div>
            </div>

            {/* <VerticalScrollList items={blogList ? blogList : ""} /> */}
            <EadlineCom name={"Sino-Bus Online Chinese Course"} />
            <div className="subheading">Sino-Bus Offering The Following Online Chinese Classes </div>
            {coursesDataList?.map((item, index) => {
                if (index === 0) {
                    return <div className="courseMol typeArea" key={index}>
                        <div className="courseImg"><img src={item.photo} alt="" /></div>
                        <div className="right">
                            <div className="rightTitle">
                                <span className="round"></span>{item.name}
                            </div>
                            {
                                item.courseDetailList.map((item, index) => {
                                    return <div key={index}> <div className="word1">{item.name}</div>
                                        <div className="word2">{item.content}</div></div>
                                })
                            }
                        </div>
                    </div>
                } else if (index === 1) {
                    return <div key={index}> {smallTitleCom(item.name)}
                        <div className="copywriting typeArea"> {item.content}  </div>
                        <div className="schoolBox typeArea">
                            {
                                item.courseDetailList.map((item, index) => {
                                    return <div className="schoolItem" key={index}>
                                        <div className="schoolItemLeft" style={{ backgroundColor: list[index].bagColor, color: list[index].color }}>{list[index].number}</div>
                                        <div className="schoolItemRight">{item.content}</div>
                                    </div>
                                })
                            }
                        </div></div>
                } else if (index === 2) {
                    // return <div key={index}>
                    //     {smallTitleCom(item.name)}
                    //     <div className="coursesBox typeArea" >
                    //         {item.courseDetailList.map((item, index) => {
                    //             return coursesCom(item, index)
                    //         })}
                    //     </div>
                    // </div>

                }
            })}
            {/* <EadlineCom name={"Start Your Learning Journey now"} />
            <div className="payBox typeArea">
                {payTypesList.map((item, index) => {
                    return payBoxCom(item, index);
                })}
            </div> */}
            <EadlineCom name={"Why Sino-bus?"} />
            <div className="reasonBox typeArea">
                {reasonList.map((item, index) => {
                    return (
                        <div className="reasonItem" key={index}>
                            <div className="reasonImg">
                                <img src={item.picture} alt="" />
                            </div>
                            <div className="reasonWord">{item.title}</div>
                        </div>
                    )
                })}
            </div>
            <div className="swiperMod">
                <div className="leftIcon" onClick={() => { carouselRef.current.prev() }}><LeftOutlined /> </div>
                <div style={{ width: "80%" }}>
                    <div>
                        <Carousel effect="fade" ref={carouselRef}>
                            {parentsList?.map((item, index) => (
                                <div key={index}>
                                    <div>
                                        <div className="parentsModule typeArea">
                                            <div className="mob"> <EadlineCom name={item.introduce} /></div>
                                            <div className="parentsImg"><img className="" src={item.picture} alt="" /></div>
                                            <div className="parentsWord">
                                                <div className="pc">
                                                    <EadlineCom name={item.introduce} />
                                                </div>
                                                <div className="parentsInfo">
                                                    <img className="comma" src="../assets/singapore/comma.png" alt="" />
                                                    <span className="studentFeedback">{item.message}</span
                                                    >
                                                </div>
                                                <div className="parentsName">{item.name}</div>
                                                <div className="years">{item.age}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className="rightIcon" onClick={() => { carouselRef.current.next() }}>
                    <RightOutlined />
                </div>
            </div>
            {/* <Swiper
                loop={true}
                autoplay={{ disableOnteraction: false }}
                slidesPerView="1"
                navigation={true}
                modules={[Navigation, Pagination, EffectCreative]}
                grabCursor={true}
                pagination={{ clickable: true }}
                ref={swiperRef1}
                creativeEffect={{
                    prev: {
                        modifier: 'coverflow',
                    },
                    next: {
                        modifier: 'coverflow',
                    },
                }}
            >
                {parentsList?.map((item, index) => (
                    <SwiperSlide key={index} >
                        <div className="parentsModule typeArea">
                            <div className="mob"> <EadlineCom name={item.introduce} /></div>
                            <div className="parentsImg"><img className="" src={item.picture} alt="" /></div>
                            <div className="parentsWord">
                                <div className="pc">  <EadlineCom name={item.introduce} /></div>

                                <div className="parentsInfo">
                                    <img className="comma" src="../assets/singapore/comma.png" alt="" />
                                    <span className="studentFeedback">{item.message}</span
                                    >
                                </div>
                                <div className="parentsName">{item.name}</div>
                                <div className="years">{item.age}</div>
                            </div>
                        </div>
                    </SwiperSlide>))}
            </Swiper>*/}
            <div className="teacherBox">
                <EadlineCom name={"Sino-Bus Professional Tutor"} />
                <div className="teachersPc">
                    <div className="teachers   typeArea">
                        {
                            teacherDataList?.map((item, index) => {
                                return teachersCom(item, index);
                            })
                        }

                    </div>
                </div>
                <div className="teachersMob">
                    <div className="teachers">
                        <Swiper
                            loop={true} //设置循环轮播
                            autoplay={{ disableOnteraction: false }}
                            slidesPerView="1" //设置显示的数量
                            navigation={true} //modules上加了同时要设置为true，才显示
                            modules={[Navigation, Pagination, EffectCreative]}
                            grabCursor={true}
                            pagination={{ clickable: true }}
                            ref={swiperRef}
                            creativeEffect={{
                                prev: {

                                },
                                next: {
                                },
                                //显示五个堆叠的最重要的这个属性，后面依次以前面属性等比配置
                                // shadowPerProgress:true,//是否等比配置透明度
                            }}
                        >
                            {teacherDataList?.map((item, index) => (
                                <SwiperSlide key={index} style={{ width: "100%", transform: (0, 0, 0), height: "auto" }}>
                                    {
                                        teachersCom(item, index)
                                    }
                                </SwiperSlide>))}
                        </Swiper>

                    </div>

                </div></div>
            <EadlineCom name="Blog" />
            {
                <BlogCom data='2' />
            }
            <EadlineCom name={"It's as easy as 3 steps"} />
            <div className="flowPath typeArea">
                <div className="flowPath1 flowPathItem">
                    <img className="stepImg" src="../assets/singapore/step1.png" alt="" />
                    <p className="stepTitle">Sign up/Login</p>
                    <p className="stepContent">Register in Email or Login your account now</p>
                </div>
                <div className="arrow">{">>"}</div>
                <div className="flowPath2 flowPathItem">
                    <img className="stepImg" src="../assets/singapore/step2.png" alt="" />
                    <p className="stepTitle">Chat Contact</p>
                    <p className="stepContent">We’ll contact you by WhatsApp for your needs</p>
                </div>
                <div className="arrow">{">>"}</div>
                <div className="flowPath3 flowPathItem">
                    <img className="stepImg" src="../assets/singapore/step3.png" alt="" />
                    <p className="stepTitle">Trial Class</p>
                    <p className="stepContent">Our Class Arranging teacher will add you and rrange a suitable teacher for your trial
                        class!</p>
                </div>
            </div>
        </div >
    )
}
export default SingaporePage;