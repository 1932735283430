import { useState, useEffect } from 'react';
import './style.scss'
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const HkCom = () => {

    const [titleUl, setTitleUl] = useState();
    let isTrue = false
    useEffect(() => {
        setTitleUl(document.querySelector('.titleUl'));
        if (isTrue === false) {
            isTrue = true;
            let headHtml = `
            <script>
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MH29QND');
        </script>
        <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND" height="0" width="0"
                style="display:none;visibility:hidden">
            </iframe>
        </noscript>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-030BVYLGM8"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-030BVYLGM8');
        </script>
        <meta name="description" content="港澳中文補習，選擇用心的Sinobus">
        <meta property="og:image"
            content="http://sino-java.oss-cn-shenzhen.aliyuncs.com/2024-04-28/%E6%96%B0%E5%A4%B4%E5%83%8F1714268640679.png?Expires=4867868640&OSSAccessKeyId=LTAI5t7JyJTMUGbf7rtfejnV&Signature=c6O5bXLKVFs8RMbNnmFsNYFlwYo%3D" />
        `
            let head = document.querySelector("head");
            head.innerHTML += headHtml;
            document.title = '中文科，線上補';

            let list = [
                { img: "../assets/Adimg/1.png", title: "幼小銜接課(K1-K3)", info1: "設有看圖認字、筆順結構、聯想造詞、短文理解、看圖寫作、故事續寫等内容;", info2: '通過趣味性的練習，在带動兒童興趣及思維的基礎上，让學生體驗學習中文的快樂，並幫助學生銜接小一課程。' },
                { img: "../assets/Adimg/2.png", title: "校本同步課程(P1-P6)", info1: "課程設計結合香港小學學習字詞表，整合《活學中國語文》《我愛學語文》《現代中國語文》【樂思】【啟思】【躍思】等相關配套練習編撰而成。", info2: '重視認字組句、標點符號、閱讀理解、習作表達等方面能力的提升。' },
                { img: "../assets/Adimg/3.png", title: "閱讀寫作專項課(P1-S3)", info1: "閱讀課程包含對詞語、句型、段落的分析練習，通過閱讀、劃分句段、整合框架等方法了解文章内容主旨，提升閱讀敏感度，掌握答題技巧。", info2: '習作課程會进行句型操練，包括組詞成句、重組句子、擴張句子、仿寫等練習。積累好詞好句，學会運用修辭手法，并掌握不同文體的寫作方法。' },
                { img: "../assets/Adimg/44.png", title: "呈分試專項課(P4-P6)", info1: "課程包括聆聽、說話、閱讀習作三部分，注重中文綜合能力的提高。", info2: '教授答題技巧，集中補底拔尖；对錯題进行歸納分析，鞏固語基、文法知識，强化審題能力並建立個人好詞佳句數據庫，以提升呈分試應試能力。' },
                { img: "../assets/Adimg/55.png", title: "中學中文專科班(S1-S3)", info1: "課程包括文言文、白話文閱讀理解及寫作專項。系統學習實虚詞、古今異義、特殊文言句式等多個考點。", info2: '課程緊密贴合教材，多元拓展以促進學生思辨能力的發展；通過品讀經典名家作品，掌握多種閱讀策略，結合考試題型幫助學生掌握白話文閱讀答題技巧，渗入DSE題型，提前熟悉考試模式；激發學生寫作興趣，從審題、立意、構思、選材等方面入手，解決學生寫作難點，提升寫作能力。' },]
            var listItems = document.querySelectorAll("li");
            var courseDiv = document.querySelector(".courseDiv");
            var container = document.querySelector('ul')
            //给每个li绑定事件
            listItems.forEach((item, index) => {
                item.addEventListener('click', function (event) {
                    courseDiv.innerHTML = `<div class="courseItem"> <div class="courseImg"><img src="${list[index].img}" alt=""></div>
                <div class="courseText">
                    <div class="courseTexttitle">${list[index].title}</div>
                    <div class="courseTextinfo1">${list[index].info1}</div>
                    <div class="courseTextinfo2">${list[index].info2}</div>
                </div></div>`;
                    // 将所有列表项的样式还原
                    listItems.forEach(item => {
                        item.classList.remove('selected');
                    });
                    // 仅将当前点击的列表项样式修改为选中样式
                    this.classList.add('selected');
                    const containerRect = container.getBoundingClientRect();
                    const offsetX = event.clientX - containerRect.left;
                    console.log("offsetX", offsetX);
                    console.log("containerRect.width - 50", containerRect.width - 50);
                    if (offsetX < 300) { // 如果点击在左边缘
                        container.scrollBy({
                            left: -200, // 设置滚动的距离
                            behavior: 'smooth' // 平滑滚动
                        });
                    } else if (offsetX > containerRect.width - 150) { // 如果点击在右边缘
                        container.scrollBy({
                            left: 200, // 设置滚动的距离
                            behavior: 'smooth' // 平滑滚动
                        });
                    }

                });
            });
        }
    }, []);
    const go = (className) => {
        var height = document.querySelector(className).offsetTop;
        console.log("高度", height)
        // scrollTo(0, height);
        window.scrollTo({
            top: height - 40,
            behavior: 'smooth' // 平滑滚动效果
        });
    }
    const isShowHandle = () => {
        console.log('titleUl', titleUl);
        if (titleUl)
            if (titleUl.classList.length > 1) {
                titleUl.classList.remove('show');
            } else {
                titleUl.classList.add('show');
            }
    }
    const moreClick = () => {
        // console.log("go");
        window.location.href = "https://wa.me/85257484737";
    };
    let serveList = [
        { img: "../assets/hkinfo/s1.png", name: "班主任", info: `了解學員需求 <br/> 按需匹配試聽課 <br/> 跟蹤課程反饋 <br/> 制定適配的課程計劃` },
        { img: "../assets/hkinfo/s2.png", name: "授課老師", info: "沉浸式遊戲化互動教學<br/>1對1作業批改<br/>及時有效地跟蹤學員學習情況<br/>與家長反饋溝通" },
        { img: "../assets/hkinfo/s3.png", name: "輔導老師", info: "學員課程安排<br/>學情反饋<br/>答疑解惑<br/>課程內容拓展" },
        { img: "../assets/hkinfo/s4.png", name: "質檢老師", info: "課程內容優化<br/>保證定制課程質量<br/>遠程設備調試<br/>監控上堂情況" }
    ];
    const serveCom = (name) => {
        return serveList?.map((item, index) => {
            return <div className={`serveItem ${name}`} key={index} >
                <img className={`img${index + 1}`} src={item.img} alt="" />
                <div className={name == 'serveMob' ? 'serveRight' : ''}>
                    {name == 'servePc' ? <div className="line"></div> : ""}
                    <div className="name">{item.name}</div>
                    {name == 'serveMob' ? <div className="line"></div> : ""}
                    <div className="serveInfo"  >
                        {item.info.split('<br/>').map((line, lineIndex) => (
                            <p key={lineIndex}>
                                {line}
                                {lineIndex > 0 && <br />}
                            </p>

                        ))}</div>
                </div>
            </div>
        })
    }
    return (
        <div className="hkPages">
            <div className="headers">
                <div className="titleNav">
                    <div className="titleLogo"> <img src="../assets/logo.png" alt="" />
                    </div>
                    <div className="titleUl">
                        <div className="titleItem" onClick={() => { go('.one1') }}>課程介紹</div>
                        <div className="titleItem" onClick={() => { go('.tow') }}>課程特色</div>
                        <div className="titleItem" onClick={() => { go('.three') }}>師資介紹</div>
                        <div className="titleItem" onClick={() => { go('.four') }}>配套服務</div>
                        <div className="titleItem" onClick={() => { go('.five') }}>關於Sinobus</div>
                        <div className="titleItem" onClick={() => { go('.six') }}>公司地址</div>
                    </div>
                    <div className="titleUlMob" onClick={() => { isShowHandle() }}>
                        <img src="../assets/hkinfo/menu.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="banner">
                <img className="bannerPc" src="../assets/hkinfo/bannerNew.png" alt="" />
                {/* <img onClick={() => { moreClick() }} className="bannerPc foot1" src="../assets/hkinfo/foot1.png" alt="" /> */}
                <img className="bannerMob" src="../assets/hkinfo/hkBannerMob3.png" alt="" />
                <a href='https://wa.me/85257484737'><img className="btnImg"
                    src="../assets/hkinfo/freeBtn.png" alt="" /></a>
                <div className="ImgMob" >
                    <a href='https://wa.me/85257484737'>
                        <p className="imgMob-text">免 費 預 約 試 堂</p>
                    </a>
                </div>
            </div>
            <div className="seniorityDiv">
                <div class="title one1">課程介紹</div>
                <ul class="calssNav">
                    <li class="selected">幼小銜接課</li>
                    <li>校本同步課程</li>
                    <li>閱讀寫作專項課</li>
                    <li>呈分試專項課</li>
                    <li>中學中文專科班</li>
                </ul>
                <div class="courseInfo">
                    <div class="courseDiv">
                        <div class="courseItem">
                            <div class="courseImg"><img src="../assets/Adimg//1.png" alt="" /></div>
                            <div class="courseText">
                                <div class="courseTexttitle">幼小銜接課(K1-K3)</div>
                                <div class="courseTextinfo1">設有看圖認字、筆順結構、聯想造詞、短文理解、看圖寫作、故事續寫等内容;</div>
                                <div class="courseTextinfo2">通過趣味性的練習，在带動兒童興趣及思維的基礎上，让學生體驗學習中文的快樂，並幫助學生街接小一課程。</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="seniority">
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T1.png" alt="" />
                        <div>
                            <div className="seniorityText1">500+</div>
                            <div className="seniorityText2">港澳授課師資</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T2.png" alt="" />
                        <div>
                            <div className="seniorityText1">100%</div>
                            <div className="seniorityText2">教師本科以上學歷</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T3.png" alt="" />
                        <div>
                            <div className="seniorityText1">200,000+</div>
                            <div className="seniorityText2">累計完課頻次</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T4.png" alt="" />
                        <div>
                            <div className="seniorityText1">100,000+</div>
                            <div className="seniorityText2">品牌粉絲</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bannerImg classTs">
                <div className="title title2 tow">課程特色</div>
                <img src="../assets/hkinfo/feature22.png" className="addressPc" alt="" />
                <img src="../assets/hkinfo/featureMob2.png" className="addressMob" alt="" />
            </div>
            {/* <div className="appointment"> <button onClick={() => { moreClick() }} className="learn-more">立即咨詢</button></div> */}
            <div className="title three">老師介紹</div>
            <Carousel arrows infinite={false} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
                {/* <div  >
                    <img className="theacher-img" src="../assets/hkinfo/t1.png" alt="" />
                </div> */}
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t222.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t333.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t444.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t55.png" alt="" />
                </div>
            </Carousel>
            <div className="seniorityDiv serveDiv" style={{ padding: 0, marginTop: '40px' }}>
                <div className="title four">配套服務</div>
                <div className="serve">
                    {
                        serveCom('servePc')
                    }
                    {
                        serveCom('serveMob')
                    }
                </div>
                <br />
                {/* <div className="appointment"> <button onClick={() => { moreClick() }} className="learn-more">立即咨詢</button></div> */}
                <div className="title five">關於Sinobus</div>
                <div className="about">
                    Sinobus由漢和文化科技有限公司創辦，總部位於中國·深圳，在中國·香港、中國·中山、中國·西安、美國·洛杉磯、新加坡均設有分公司。公司專注于為K1-K3、P1-P6、S1-S3港澳學童及4-16歲海外華裔兒童提供系統的中文/數學培訓服務。定製化的課程內容，全心全意的配套服務，幫助孩子實現高效學習。
                </div>
            </div>
            <div className="title title2 address six">公司地址</div>
            <div className="bannerImg" style={{ backgroundColor: 'white' }}>
                <img src="../assets/hkinfo/addressPc.png" className="addressPc" alt="" />
                <img src="../assets/hkinfo/addressMob2.png" className="addressMob" alt="" />
                <div className="footer-hk">
                    <div className="footer-item">
                        <p className="footer-title">中國深圳辦公中心 | 全球總部</p>
                        <p className="footer-p">地址： 中國深圳市南山區沙河街道文昌街社區香山裏花園</p>
                        <p className="footer-p">電話：19925317349</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司香港分公司
                            <br /> 中國香港辦公中心
                        </p>
                        <p className="footer-p">地址：FLAT/RM 7022 BLK D 7/F TAK WING IND BLDG 3 TSUN WEN ROAD TUEN MUN NT</p>
                        <p className="footer-p">電話：852 68567158</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司西安分公司<br /> 中國西安辦公中心 | 教研基地</p>
                        <p className="footer-p">地址： 中國西安市雁塔區曲江池南路金地湖城大境</p>
                        <p className="footer-p">電話：029-89123322</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司中山分公司</p>
                        <p className="footer-p">地址： 中國中山市港口鎮興港中路136號</p>
                        <p className="footer-p">電話：076088512186</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">美國洛杉矶辦公中心 | 運營基地</p>
                        <p className="footer-p">Los Angeles, USA Limited</p>
                        <p className="footer-p">地址：75 egret, Irvine,California, 92618</p>
                        <p className="footer-p">電話：+1 2403026131</p>
                    </div>
                </div>

                <br />
                <div className="footerDiv">
                    <div className="footerItem item1">
                        <div className="footerLogo">
                            <img src="../assets/hkinfo/logo.png" alt="" />
                        </div>
                        <div className="chatText">
                            聯繫我們
                        </div>
                        <div className="addressMob">
                            <img style={{ width: '150px', marginBottom: '10px' }}
                                src="../assets/hkinfo/code.png" alt="" />
                        </div>
                        <div className="chatType">
                            <a href="https://wa.me/85257484737"> <img
                                src="../assets/hkinfo/whatsapp.png" alt="" /></a>
                            <a href="https://www.facebook.com/profile.php?id=100082337253874"> <img
                                src="../assets/hkinfo/fb.png" alt="" /></a>
                            <a href="https://www.xiaohongshu.com/user/profile/6143a42f000000000201f6d7?xhsshare=WeixinSession&appuid=6143a42f000000000201f6d7&apptime=1713836968">
                                <img src="../assets/hkinfo/redbook.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="footerItem item2">
                        <ul className="classList">
                            <li className="classList-one">sinobus課程</li>
                            <li>港澳普通話培訓課程</li>
                            <li>港澳K1-P6中文補習</li>
                            <li>港澳K1-P6數學補習</li>
                            <li>港澳S1-S3中文補習</li>
                        </ul>
                    </div>
                    <div className="footerItem item3">
                        <img src="../assets/hkinfo/code.png" alt="" />
                    </div>
                </div>
                <div className="chatDiv">
                    <div>
                        <a href='https://wa.me/85257484737'> <img className="chatIcon"
                            src="../assets/hkinfo/WhatsApp.png" alt="" /></a>
                    </div>
                    <div>
                        <a href='https://m.me/61554822001745'>
                            <div className="suspend">免費預約試堂</div>
                        </a>
                    </div>
                </div>
                <div id="floaty-window" style={{ display: 'none' }}></div>
            </div>


        </div>
    )
}
export default HkCom;