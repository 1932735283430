import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.scss"
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import FooterCom from "../../components/FooterCom"
import { MessageOutlined, HeartOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space, message } from 'antd';
import { useState } from 'react';
import { addBlogAPI, getCommentListAPI, getBlogContent } from "../../api/index"
const BlogInfo = () => {
    // const location = useLocation();
    let url = window.location.pathname.replace(/^\//, '')
    const [isShow, setIsshow] = useState(false);
    const [blogList, setBlogList] = useState([]);
    const [content, setContent] = useState('');
    const [blogContent, setBlogContent] = useState('');
    useEffect(() => {
        getLsit();
    }, []);
    const getLsit = () => {
        getBlogContent(url).then((res) => {
            setBlogContent(res.data);
            if (res.code === 200) {
                getCommentListAPI(res.data.blogsId).then((res) => {
                    setBlogList(res.data);

                })
            }
        });

    };
    const nav = useNavigate();
    const goHome = () => {
        //回首页
        nav("/")
    };
    // 提交
    const subHandle = () => {
        addBlogAPI({
            "content": content,
            "blogsId": blogContent.blogsId
        }).then((res) => {
            if (res.code === 200) {
                message.info('评论成功');
                setContent("")
                getLsit();
            }
        })
    };
    const handleChange = (e) => {
        setContent(e.target.value);
    };
    return <div className='blogInfo'>
        <div className='titleDiv' onClick={goHome}>
            <img src="../assets/logo.png" alt="" />
        </div>
        <div className='content'>
            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: blogContent?.content }} />
            {/* <div className='commentDiv'>
                {blogContent?.licence === '1' ? <MessageOutlined onClick={() => { setIsshow(!isShow) }} className='comment' style={{ fontSize: '24px', color: '#0000007a' }} /> : ""}
                {
                    isShow === true ? <div className='commentContent'>
                        <Space.Compact
                            style={{
                                width: '100%',
                                marginBottom: "10px"
                            }}
                        >
                            <Input defaultValue="Combine input and button" value={content} onChange={handleChange} />
                            <Button type="primary" onClick={subHandle}>评论</Button>
                        </Space.Compact>
                        {
                            blogList?.map((item, index) => {
                                return <div className='commentItem' key={index}>
                                    <div className='info'>  <HeartOutlined style={{ color: "#ff4d4f" }} /> {item.content}</div>
                                    <div className='time'>{item.createTime}</div>
                                </div>
                            })
                        }
                        <div onClick={() => { setIsshow(false) }}> 收起</div>
                    </div> : ''
                }
            </div> */}
        </div>
        <div className='footer'>
            <FooterCom />
        </div>
    </div>
}
export default BlogInfo;