import "./style.scss"
import { useEffect } from "react";
import HeaderCom from "../../components/HeaderCom"
import { Outlet } from "react-router-dom";
import FooterCom from "../../components/FooterCom";
import { systemConfigList } from "../../api/index.js";
const Home = () => {
    let isShow = false;
    useEffect(() => {
        let head = document.querySelector("head");
        let body = document.querySelector("body");
        const div = document.createElement('div');

        systemConfigList().then((response) => {
            if (isShow === false && response.data) {
                isShow = true;
                let headHtml = '';
                let bodyHtml = '';
                response.data?.map((item) => {
                    if (item.licence == '1') {
                        if (item.code != '') {
                            headHtml += item.code
                            console.log("--------", headHtml);
                        }
                        if (item.codeSecond != '') {
                            bodyHtml += item.codeSecond
                        }
                    }
                })
                if (headHtml) {
                    // head.innerHTML += headHtml;
                    const newElement = document.createElement('script');
                    newElement.innerHTML = headHtml;
                    document.head.appendChild(newElement)
                }
                if (bodyHtml) {
                    // body.innerHTML += bodyHtml;
                    const newElement = document.createElement('noscript');
                    newElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
                    document.body.appendChild(newElement)
                }

            }
        });
    }, [])

    // const count = useSelector(state => state.counter.value)

    return (
        <>
            <div className="headerCom">
                <HeaderCom />
            </div>
            <div className="contentBox"> <Outlet />
                <FooterCom />

            </div>

        </>
    )
}
export default Home;